var mobile = 0,
    windowW = 0,
    overviewTop,
    itineraryTop,
    includesTop,
    ratesTop,
    galleryTop,
    bookingTop,
    hash,
    activeLink = 0;
$(document).ready(function($) {
    $('a.to-hash').each(function(){
        $(this).on('click', function() {
            var target = $(this).attr('href');
            var targetSt = $(target).offset().top;
            var speed = 500;
            var headerH = 60;
            $("html, body").animate({
                scrollTop: targetSt - headerH
            }, speed);
        });
    });

    $('input[data-name="image"]').each(function(){
        $(this).css('cursor','pointer');
        $(this).on('click', function(){
            $(this).next('label.browse').click();
        })
    })

    jQuery("time.timeago").timeago();
    $('li[data-active]').each(function(){
        $(this).removeClass('active');
        var active = $(this).data('active');
        if(window.location.href.indexOf(active) > -1 && activeLink == 0) {
            $(this).addClass('active');
            var parent = $(this).parent('ul');
            $(parent).parent('li').addClass('active');
            activeLink = 1;
        }
    });
    if(activeLink == 0){
        $('li[data-active="home"]').addClass('active');
    }
    if ($('#search').height() != undefined) {
        var tours = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('tour'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            prefetch: '/json/tours.json',
            remote: {
                url: '/json/tours.json'
            }
        });

        $('#search .typeahead').typeahead(null, {
            name: 'tour',
            display: 'value',
            source: tours
        });

        $('#search-text').on('keyup', function(){
            if($(this).val() == ''){
                $('.c-icon').removeClass('active');
                $('.s-icon').prop('disabled', true);
            } else {
                $('.c-icon').addClass('active');
                $('.s-icon').prop('disabled',false);
            }
            if(event.keyCode==13){
                $('.s-icon').click();
            }
        });

        $('.s-icon').on('click', function(){
            if($(this).prop('disabled') != true){
                window.location = '/search/'+$('#search-text').val();
            }
        });

        $('.c-icon').on('click', function(){
            $('#search-text').val('');
            $(this).removeClass('active');
        });
    }


    if ($('a[data-rel^=lightcase]').height() != undefined) {
        $('a[data-rel^=lightcase]').lightcase({
            transition: 'scrollHorizontal',
            transitionOpen: 'fade',
            transitionClose: 'fade',
            swipe: true
        });
    }

    if ($('span[data-rel^=lightcase]').height() != undefined) {
        $('span[data-rel^=lightcase]').lightcase({
            transition: 'scrollHorizontal',
            transitionOpen: 'fade',
            transitionClose: 'fade',
            swipe: true
        });
    }

    if ($('div[data-rel^=lightcase]').height() != undefined) {
        $('div[data-rel^=lightcase]').lightcase({
            transition: 'scrollHorizontal',
            transitionOpen: 'fade',
            transitionClose: 'fade',
            swipe: true
        });
    }

    $('.lbox').each(function() {
        $(this).on('click', function() {
            $(this).next('a').click();
        });
    });
    windowW = $(window).width();
    $('.toggle').on('click', function() {
        if (mobile == 1) {
            $(this).children('i').each(function() {
                if ($(this).hasClass('hdn')) {
                    $(this).removeClass('hdn').addClass('vsb').fadeIn(500);
                } else {
                    $(this).removeClass('vsb').addClass('hdn').fadeOut(500);
                }
            });
            var text = $(this).children('span').html();
            if (text == 'MENU') {
                $(this).children('span').html('CLOSE');
            } else {
                $(this).children('span').html('MENU');
            }
            $('.primary-nav').fadeToggle();
        }
    });

    $('.has-list').on('click', function() {
        if (mobile == 1) {
            $(this).children('ul').slideToggle();
        }
    });

    $('#modal').each(function() {
        $(this).on('click', function(e) {
            e.stopPropagation();
        });
    });
    $('#modal-blocker').each(function() {
        $(this).on('click', function(e) {
            $('#modal-blocker').each(function() {
                $(this).addClass('closed');
            });
        });
    });

    $('.more-bt').each(function() {
        var container = $(this).closest('.has-more');
        $(this).on('click', function() {
            $(container).addClass('expanded');
            $("html, body").animate({
                scrollTop: $(window).scrollTop() + 100
            }, 500);
        })
    });
    $('.less-bt').each(function() {
        var container = $(this).closest('.has-more');
        $(this).on('click', function() {
            $(container).removeClass('expanded');
            hash = window.location.hash;
            scrollToHash(hash);
        })
    });

    $('.more-filters').on('click', function() {
        if ($(this).html() == 'more filters') {
            $('#filters').slideDown();
            $(this).html('less filters');
        } else if ($(this).html() == 'less filters') {
            $('#filters').slideUp();
            $(this).html('more filters');
        } else if ($(this).html() == 'show options') {
            $('#booking-opts').slideDown();
            $(this).html('hide options');
        } else if ($(this).html() == 'hide options') {
            $('#booking-opts').slideUp();
            $(this).html('show options');
        }
    });

    $('.tour-nav ul li').each(function() {
        $(this).children('a').each(function() {
            $(this).on('click', function(e) {
                e.preventDefault();
            });
        });
        $(this).on('click', function() {
            var target = $(this).children('a').attr('href');
            var targetSt = $(target).offset().top;
            var speed = 500;
            var headerH;
            if (windowW <= 720) {
                headerH = 115;
            } else {
                headerH = 79;
            }
            $("html, body").animate({
                scrollTop: targetSt - headerH
            }, speed);
        });
    });


    //Bookings
    $('.manage li').on('click', function(e) {
        e.stopPropagation();
        $('.manage li ul').each(function() {
            $(this).fadeOut();
        });
        if ($(this).children('ul').css('display') == 'none') {
            $(this).children('ul').fadeIn(200);
        } else {
            $(this).children('ul').fadeOut(200);
        }
    });
    $(document).on('click', function() {
        if ($('.manage li ul').height() != undefined) {
            $('.manage li ul').each(function() {
                $(this).fadeOut();
            });
        }
    })
});

$(window).on('resize', function() {
    windowW = $(window).width();
    $('.primary-nav').attr('style', '');
    $('.open').removeClass('hdn').addClass('vsb').attr('style', '');
    $('.close').removeClass('vsb').addClass('hdn').attr('style', '');
    $('.toggle').children('span').html('MENU');
    $('ul').each(function() {
        $(this).attr('style', '');
    });

    if ($(this).width() > 820) {
        mobile = 0;
    } else {
        mobile = 1;
    }
});

$(window).on('load', function() {
    windowW = $(window).width();
    if ($(this).width() > 820) {
        mobile = 0;
    } else {
        mobile = 1;
    }
});

$(window).on('scroll', function() {
    var sTop = $(window).scrollTop();
    var sTopE;
    if (sTop >= 50) {
        if($('.header').hasClass('admin')){

		} else {
			$('.header').addClass('mini');
        	$('.content-placeholder').addClass('mini');
		}
    } else if (sTop < 50) {
        $('.header').removeClass('mini');
        $('.content-placeholder').removeClass('mini');
    }
    // Tour Page
    if ($('#overview').height() != undefined) {
        overviewTop = $('#overview').offset().top,
            itineraryTop = $('#itinerary').offset().top,
            includesTop = $('#includes').offset().top,
            ratesTop = $('#rates').offset().top,
            galleryTop = $('#gallery').offset().top,
            bookingTop = $('#booking').offset().top;

        if (windowW <= 720) {
            sTopE = sTop + 120;
        } else {
            sTopE = sTop + 80;
        }

        if (sTopE >= overviewTop) {
            $('.tour-nav').addClass('sticky');
        } else {
            $('.tour-nav').removeClass('sticky');
        }

        if (sTopE <= itineraryTop) {
            $('.tour-nav ul li').each(function() {
                $(this).removeClass('active');
                if ($(this).children('a').attr('href') == '#overview') {
                    $(this).addClass('active');
                }
            });
            $('.rating-bar').each(function() {
                var rating = $(this).data('rating');
                $(this).addClass('r' + rating);
            });
        } else if (sTopE >= itineraryTop && sTopE <= includesTop) {
            $('.tour-nav ul li').each(function() {
                $(this).removeClass('active');
                if ($(this).children('a').attr('href') == '#itinerary') {
                    $(this).addClass('active');
                }
            });
            $('.rating-bar').each(function() {
                $(this).attr('class', 'rating-bar');
            });
        } else if (sTopE >= includesTop && sTopE <= ratesTop) {
            $('.tour-nav ul li').each(function() {
                $(this).removeClass('active');
                if ($(this).children('a').attr('href') == '#includes') {
                    $(this).addClass('active');
                }
            });
        } else if (sTopE >= ratesTop && sTopE <= galleryTop) {
            $('.tour-nav ul li').each(function() {
                $(this).removeClass('active');
                if ($(this).children('a').attr('href') == '#rates') {
                    $(this).addClass('active');
                }
            });
        } else if (sTopE >= galleryTop && sTopE <= bookingTop) {
            $('.tour-nav ul li').each(function() {
                $(this).removeClass('active');
                if ($(this).children('a').attr('href') == '#gallery') {
                    $(this).addClass('active');
                }
            });
        } else if (sTopE >= bookingTop) {
            $('.tour-nav ul li').each(function() {
                $(this).removeClass('active');
                if ($(this).children('a').attr('href') == '#booking') {
                    $(this).addClass('active');
                }
            });
        }
    }
});

//Login Functions
function showModal(url, type, e) {
    if(e != undefined){
        e.preventDefault();
    }
    var modalBlocker = document.getElementById('modal-blocker'),
        modal = document.getElementById('modal'),
        loadingModal = $(modal).children('.blocker'),
        loadingText = $(loadingModal).find('.ldr .blocker-text'),
        modalContent = document.getElementById('modal-content'),
        showFunction = "'" + url + "'";
    $(modal).attr('class', type);
    $(modalBlocker).removeClass('closed');
    $(loadingModal).addClass('active');
    $.ajax({
        url: url,
        type: 'GET',
        timeout: 10000,
        beforeSend: function() {
            $(loadingText).html('Loading...');
            $(modalContent).removeClass('acitve').html('');
        },
        success: function(res) {
            $(modalContent).addClass('active').html(res);
            jQuery("time.timeago").timeago();
            $(loadingModal).removeClass('active');
        },
        error: function() {
            $(loadingText).html('Faild to load!, Connection problem. <a href="JavaScript:void(0);" class="c-red" onclick="showModal(' + showFunction + ')">Retry</a>');
        },
        fail: function() {
            $(loadingText).html('Faild to load!, Connection problem. <a href="JavaScript:void(0);" class="c-red" onclick="showModal(' + showFunction + ')">Retry</a>');
        }
    });
}

function closeModal() {
    var modalBlocker = document.getElementById('modal-blocker');
    var modal = document.getElementById('modal');
    var loadingModal = $(modal).children('.blocker');
    var modalContent = document.getElementById('modal-content');
    $(modal).attr('class', '');
    $(modalBlocker).addClass('closed');
    $(loadingModal).addClass('active');
    $(modalContent).removeClass('acitve').html('');
};

//Preview Images
function imgPreview(input, img) {

    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
            $(img).attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]);
    }
}

function imgPreviewBg(input, img, view) {

    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
            $(view).css({
                        'transition':'0.4s background ease-in',
                        'background':"#fff url('"+e.target.result+"') no-repeat center center",
                        'background-size':"cover"
                        });
            $(img).css('visibility', 'hidden');
        }

        reader.readAsDataURL(input.files[0]);
    }
}

function scrollToHash(target) {
    $('a').each(function() {
        if ($(this).attr('href') == target) {
            var btn = $(this);
            setTimeout(function() {
                $(btn).click();
                window.history.pushState("", document.title, window.location.pathname);
            }, 50);
        }
    });
}
function removeHasErrors()
{
    $('.form-control').each(function(){
        $(this).removeClass('has-errors');
    });
}

function showHasErrors(key)
{
    $('.form-control').each(function(){
        if($(this).attr('name') == key){
            $(this).addClass('has-errors');
        }
        if($(this).data('name') == key){
            $(this).addClass('has-errors');
        }
    });
}
function validateForm() {
    var hasErrors = false;
    removeHasErrors();
    hideAlert();
    $('.required').each(function() {
        var inputVal = $(this).val();
        if (inputVal == '' || inputVal == 0 || inputVal == null) {
            $(this).addClass('has-errors');
            hasErrors = true;
        }
    });
    $('.alert').addClass('danger active');
    $('#modal-alert-text').html('Please fill in the required fields');
    scrollToFirst('has-errors');
    return hasErrors;
}

function validateFormS() {
    var hasErrors = false;
    removeHasErrors();
    hideAlert();
    $('.requireds').each(function() {
        var inputVal = $(this).val();
        if (inputVal == '' || inputVal == 0 || inputVal == null) {
            $(this).addClass('has-errors');
            hasErrors = true;
        }
    });
    $('.alert').addClass('danger active');
    $('#form-alert-text').html('Please fill in the required fields');
    scrollToFirst('has-errors');
    return hasErrors;
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function openUrl(url){
    window.open(url, '_self');
}

function showLoading(blocker, message){
    hideAlert();
    loadingText = $(blocker).find('.ldr .blocker-text');
    $(blocker).addClass('active');
    $(loadingText).html(message);
}

function hideLoading(){
    $('.blocker').each(function(){
        $(this).removeClass('active');
    });
}

function showAlert(status, text, details){
    $('.alert').addClass(status+' active');
    $('#modal-alert-text').html(text);
    if(details != undefined || details == ''){
        $('#alert-details').removeClass('hidden').html(details);
    }
}

function hideAlert(){
    $('.alert').attr('class','alert');
    $('#modal-alert-text').html('');
    $('#alert-details').addClass('hidden').html('');
}

function slug(input) {
    val = $(input).val();
    $('#slug').val(val.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').replace('_','-').toLowerCase());
}
function groupSlug(input1,input2,input3) {
    val1 = $(input1).val();
    val2 = $(input2).val();
    val3 = $(input3).val();
    rVal = val1+' '+val2+' '+val3;
    val = rVal.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').replace('_','-').toLowerCase();
    $('#slug').val(val);
}

function clearForm(form) {
    $(form).find('.form-group').each(function(){
        $(this).find('input[type=text]').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=number]').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=email]').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=tel]').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=date]').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=time]').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=datetime]').each(function(){
            $(this).val('');
        });
        $(this).find('textarea').each(function(){
            $(this).val('');
        });
        $(this).find('input[type=checkbox]').each(function(){
            $(this).prop('checked', false);
        });
        $(this).find('input[type=radio]').each(function(){
            $(this).prop('checked', false);
        });
        $(this).find('select').each(function(){
            $(this).val('0');
        });
        $(this).find('img').each(function(){
            $(this).attr('src','');
        });
    });
}

function scrollToFirst(className) {
    var target = document.getElementsByClassName(className)[0];
    if($(target).height() != undefined) {
        var targetSt = $(target).offset().top;
        $("html, body").animate({
            scrollTop: targetSt - 70
        }, 1000);
    }
}

function scrollToId(id,gap) {
    var target = document.getElementById(id);
    if($(target).height() != undefined) {
        var targetSt = $(target).offset().top;
        $("html, body").animate({
            scrollTop: targetSt - 70 - gap
        }, 1000);
    }
}